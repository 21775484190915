import React from "react"
import { Link } from "gatsby"
import ArrowButton from "../../../ui/ArrowButton/ArrowButton"
import ScrollTopHeader from "../ScrollTopHeader/ScrollTopHeader"
import FooterSection from "../FooterSection/index"

const RiskOverview = () => {
  const links = [
    { label: "Flood", to: "/risks/flood" },
    { label: "Fire", to: "/risks/fire" },
    { label: "Heat", to: "/risks/heat" },
    { label: "Drought", to: "/risks/drought" },
    { label: "Storm", to: "/risks/storm" },
  ]

  return (
    <section className="risk-overview-section">
      <ScrollTopHeader />
      <div className="risk-overview-section-container">
        <h2>What is Your Climate Risk?</h2>
        <p>
          If you own, manage or invest in real estate properties, you should
          assess the risk of damage caused by extreme weather events, and the
          potential costs.

        </p>
        <Link to="/risks">
          <ArrowButton
            label="Climate Risk Overview"
            color="white"
            background="#37a87a"
          />
        </Link>
        <FooterSection links={links} color="white" />
      </div>
    </section>
  )
}

export default RiskOverview
