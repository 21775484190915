import React from "react"
import { Link } from "gatsby"

const FooterSection = ({ color, links }) => {
  return (
    <div className="footer">
      {links.map((e, index) => {
        return (
          <Link  key={index} to={e.to} className="footer-links" style={{ color }}>
            {e.label}
          </Link>
        )
      })}
    </div>
  )
}

export default FooterSection
