import React from "react"
import { Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

type Props = {
  label: string
  color: string
  background: string
}

const ArrowButton: React.FC<Props> = ({ label, color, background }) => {
  return (
    <button className="arrow-button" style={{ color, background }}>
      {label}{" "}
      <Icon
        icon={IconNames.ARROW_RIGHT}
        color={color}
        style={{ verticalAlign: "sub" }}
      />
    </button>
  )
}

export default ArrowButton
