import React from "react"
import ClimateCheckLogo from "../../../ui/ClimateCheckLogo/ClimateCheckLogo"

const ScrollTopHeader: React.FC<{ color?: string }> = ({ color }) => {
  const handleOnClick = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  return (
    <div className="scroll-top-header" onClick={handleOnClick}>
      <ClimateCheckLogo color={color} />
    </div>
  )
}
export default ScrollTopHeader
